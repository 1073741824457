<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <b-card v-if="itemData && ($can('read', abilityRequired) || $can('read', abilityRequired))">
      <b-card-header>
        <h5>
          Patrol - {{ itemData.patrolname }} - {{ itemData.start_date_time }}
        </h5>
        <b-button
          v-if="userData.username === itemData.username"
          variant="primary"
          class="float-right"
          @click="handleEditPatrol"
        >
          Edit Patrol
        </b-button>
      </b-card-header>
      <b-card-body>
        <!-- Input Fields -->
        <validation-observer
          ref="observer"
          #default="{ handleSubmit }"
        >
          <b-form
            @submit.prevent="handleSubmit(validateForm)"
          >
            <!-- ***** Location ***** -->
            <b-row
              class="mt-2 mb-1 pt-1 border-top-secondary"
            >
              <b-col
                md="12"
              >
                <h4>Location</h4>
              </b-col>
            </b-row>
            <!-- Field: Client Selection -->
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Client"
                  label-for="clientname"
                >
                  <h5>{{ itemData.clientname }}</h5>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Site selection -->
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Site"
                  label-for="sitename"
                >
                  <h5>{{ itemData.sitename }}</h5>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Patrol Name -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="Patrol Name"
                  label-for="patrolname"
                >
                  <h5>{{ itemData.patrolname }}</h5>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- ***** Details ***** -->
            <b-row
              class="mt-2 mb-1 pt-1 border-top-secondary"
            >
              <b-col
                md="12"
              >
                <h4>Details</h4>
              </b-col>
            </b-row>
            <!-- Field: Guard Name -->
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Guard Name"
                  label-for="guardname"
                >
                  <h5>{{ itemData.user_display_name }}</h5>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Start/End Date/Time -->
            <b-row>
              <b-col
                md="2"
              >
                <b-form-group
                  label="Start Date/Time"
                  label-for="startdatetime"
                >
                  <h5>{{ itemData.start_date_time }}</h5>
                </b-form-group>
              </b-col>
              <b-col
                md="2"
              >
                <b-form-group
                  label="Finished Date/Time"
                  label-for="finisheddatetime"
                >
                  <h5>{{ itemData.end_date_time }}</h5>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Flagged / Finished -->
            <b-row>
              <b-col
                md="2"
              >
                <b-form-group
                  label="Flagged"
                  label-for="containsflag"
                >
                  <h5
                    v-html="itemData.contains_flag ? '<span class=\'text-danger font-weight-bold\'>YES</span>' : 'No'"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="2"
              >
                <b-form-group
                  label="Unfinished"
                  label-for="unfinishedcheckpoints"
                >
                  <h5
                    v-html="itemData.unfinished_checkpoints ? '<span class=\'text-danger font-weight-bold\'>YES</span>' : 'No'"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="2"
              >
                <b-form-group
                  label="GPS Distance Violation"
                  label-for="geodistanceflag"
                >
                  <h5
                    v-html="itemData.geo_distance_flag ? '<span class=\'text-danger font-weight-bold\'>YES</span>' : 'No'"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Checkpoint Modal -->
            <b-modal
              id="checkpoint-modal"
              centered
              hide-footer
              :title="checkpointDetailsModal.name + ' Checkpoint'"
            >
              <b-form @submit.prevent="handleCheckpointSubmit">
                <b-form-group>
                  <p>{{ checkpointDetailsModal.point_description }}</p>
                </b-form-group>
                <input
                  v-model="checkpointDetailsModal"
                  type="hidden"
                  name="checkpointDetails"
                >

                <div
                  v-if="itemData.status !== 'Paused' && !nfcScanned"
                  class="text-center"
                >
                  <b-img
                    src="@/assets/images/icons/nfc-scan-animation.gif"
                    width="75"
                    height="75"
                    alt="Scan NFC Tag"
                  />
                  <p class="mt-1">
                    Please scan the NFC tag
                  </p>
                </div>
                <!-- Flagged -->
                <b-form-group>
                  <b-form-checkbox
                    v-model="checkpointDetailsModal.flagged"
                    name="flagged"
                  >
                    Flagged
                  </b-form-checkbox>
                </b-form-group>
                <!-- Notes -->
                <b-form-group>
                  <b-form-textarea
                    v-model="checkpointDetailsModal.notes"
                    placeholder="Enter notes..."
                    rows="3"
                  />
                </b-form-group>
                <!-- Submit Button -->
                <b-row>
                  <b-col md="6">
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-2"
                      block
                      type="submit"
                    >
                      Submit
                    </b-button>
                  </b-col>
                  <b-col md="6">
                    <b-button
                      variant="outline-secondary"
                      class="mb-1 mb-sm-0"
                      block
                      @click="$bvModal.hide('checkpoint-modal')"
                    >
                      Cancel
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
              <!-- Photos and Images -->
              <b-card v-if="itemData && itemData.clientsite !== null && checkpointDetailsModal.name !== null && ($can('create', abilityRequired) || $can('update', abilityRequired))">
                <b-card-header>
                  <h5>
                    Photos / Images
                  </h5>
                </b-card-header>
                <b-card-body>
                  <!-- Button to trigger the file input -->
                  <div>
                    <b-row class="mb-2">
                      <b-col
                        v-if="$can('update', abilityRequired)"
                        md="2"
                      >
                        <b-button
                          variant="primary"
                          class="mb-1 mb-sm-0"
                          block
                          :disabled="preventDoubleClick"
                          @click="openCamera"
                        >
                          Take Picture
                        </b-button>
                      </b-col>
                      <b-col
                        v-if="$can('update', abilityRequired)"
                        md="2"
                      >
                        <b-button
                          variant="primary"
                          class="mb-1 mb-sm-0"
                          block
                          :disabled="preventDoubleClick"
                          @click="openImageSelector"
                        >
                          Add Image
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                  <!-- Images List -->
                  <section
                    v-if="docsAttachmentList"
                    class="grid-view"
                  >
                    <b-card
                      v-for="mediaItem in docsAttachmentList"
                      :id="mediaItem.filename"
                      :ref="mediaItem.filename"
                      :key="mediaItem.filename"
                      no-body
                    >
                      <div
                        v-if="mediaItem.filename.slice(-3) === 'jpg'"
                        class="item-img text-center"
                      >
                        <thumbs-lazy-image-component
                          class="card-img-top"
                          :src="mediaItem.access_url"
                          src-placeholder="@/assets/images/noImage.png"
                        />
                      </div>

                      <b-card-footer
                        v-if="mediaItem.filename.slice(-3) === 'jpg'"
                        class="pt-1 pb-1 border-dark"
                      >
                        <b-row>
                          <b-col>
                            <b-button
                              variant="primary"
                              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                              block
                              :disabled="preventDoubleClick"
                              @click="deleteImage(mediaItem)"
                            >
                              Delete Image
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-card-footer>
                    </b-card>
                  </section>
                  <!-- Hidden file input for accessing camera -->
                  <input
                    ref="cameraInput"
                    type="file"
                    accept="image/*"
                    capture="environment"
                    style="display: none;"
                    @change="handleImageChange"
                  >
                  <input
                    ref="imageInput"
                    type="file"
                    accept="image/*"
                    style="display: none;"
                    @change="handleImageChange"
                  >
                </b-card-body>
              </b-card>
            </b-modal>
            <!-- ***** Completed Checkpoints List ***** -->
            <b-row
              v-if="itemData.checkpoints.length > 0"
              class="mt-2 mb-1 pt-1 border-top-secondary"
            >
              <b-col
                md="12"
              >
                <h4>Checkpoints</h4>
              </b-col>
            </b-row>
            <!-- Completed Checkpoints List -->
            <b-row
              v-if="itemData.checkpoints.length > 0"
              class="mt-2 mb-1 pt-1"
            >
              <b-col
                md="12"
              >
                <app-timeline>
                  <app-timeline-item
                    v-for="checkpoint in itemData.checkpoints"
                    :key="checkpoint.id"
                    :variant="checkpoint.code_scanned ? 'success' : checkpoint.flagged ? 'danger' : 'warning'"
                    :icon="checkpoint.checkpoint_order === 0 ? 'PauseCircleIcon' : checkpoint.flagged ? 'AlertTriangleIcon' : 'MapPinIcon'"
                  >
                    <div
                      class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                    >
                      <h6>{{ checkpoint.name }}</h6><br>
                    </div>
                    <small class="text-muted">GPS Coordinates: </small><small v-html="checkpoint.geo_lat && checkpoint.geo_long ? `${checkpoint.geo_lat}, ${checkpoint.geo_long}` : checkpoint.geo_required ? '<span class=\'text-danger font-weight-bold\'>N/A</span>' : 'N/A'" /><br>
                    <small class="text-muted">Geo Distance: </small><small v-html="checkpoint.geo_distance && checkpoint.geo_distance < 30 ? `${checkpoint.geo_distance} m` : checkpoint.geo_required ? `<span class='text-danger font-weight-bold'>${checkpoint.geo_distance} m</span>` : 'N/A'" /><br>
                    <small class="text-muted">Date/Time Submitted: </small><small>{{ checkpoint.date_time }}</small><br>
                    <small class="text-muted">Notes: </small>
                    <p v-html="checkpoint.notes ? checkpoint.notes.replace(/\n/g, '<br>') : ''" />
                    <p>
                      <template v-if="checkpoint.attachment_list">
                        <div
                          v-for="attachment in checkpoint.attachment_list"
                          :key="attachment.access_url"
                          class="item-img text-center"
                        >
                          <thumbs-lazy-image-component
                            width="500"
                            class="card-img-top"
                            :src="attachment.access_url"
                            src-placeholder="@/assets/images/noImage.png"
                          />
                        </div>
                        <!-- <b-img
                          v-for="attachment in checkpoint.attachment_list"
                          :key="attachment.access_url"
                          :src="require('@/assets/images/icons/jpg.png')"
                          height="auto"
                          width="20"
                          class="mr-1 cursor-pointer"
                          @click="$bvModal.show('modal-' + attachment.access_url)"
                        />
                        <b-modal
                          v-for="attachment in checkpoint.attachment_list"
                          :id="'modal-' + attachment.access_url"
                          :key="'modal-' + attachment.access_url"
                          hide-footer
                          centered
                        >
                          <thumbs-lazy-image-component
                            class="card-img-top"
                            :src="attachment.access_url"
                            src-placeholder="@/assets/images/noImage.png"
                          />
                        </b-modal> -->
                      </template>
                    </p>
                  </app-timeline-item>
                </app-timeline>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
// import { Storage, Auth } from 'aws-amplify'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
import { required } from '@validations'
import {
  BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BFormGroup, BForm, BCardFooter, BFormCheckbox, BFormTextarea,
  BImg,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
// import moment from 'moment'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import userPreferences from '@/store/userPreferences'
import storeModule from '../storeModule'
import clientStoreModule from '../../clients/clientStoreModule'
import useList from './useCheckPointsList'
import ThumbsLazyImageComponent from '../../thumbsLazyImageComponent'

// Notification
// import { useToast } from 'vue-toastification/composition'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// import ImageUploader from 'vue-image-upload-resize'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    ValidationObserver,
    BCardFooter,
    BFormCheckbox,
    ThumbsLazyImageComponent,
    AppTimeline,
    AppTimelineItem,
    BImg,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      preventDoubleClick: false,
      nfcScanned: false,
    }
  },
  setup() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const userPrefs = userPreferences()

    const toast = useToast()
    const abilityRequired = 'patrols'
    const storeModuleName = 'app-patrols'
    const itemTypeName = 'Patrol'
    const printTitle = 'Patrols'
    const allowPrinting = false
    const allowApproval = false
    const filesDir = 'company/patrols'
    const maxFiles = 0 // Use 0 for no files otherwise specify max number
    const supportedFileTypes = ['*'] // Use * for all types, otherwise use an array of file extensions
    const maxImages = 0 // Use 0 for no images otherwise specify max number
    const supportedImageTypes = ['jpg']

    const clientsData = ref([])
    const clientOptions = ref([])
    const sitesData = ref([])
    const siteOptions = ref([])
    const patrolnameOptions = ref([])

    let theClientSite = null
    let clientsite = null
    let thePatrolName = null
    let SK = null
    const thePatrolTemplate = ref(null)
    const theCheckpoints = ref(null)
    const theCompletedCheckpoints = ref(null)
    const checkpointDetailsModal = ref({
      name: null,
      point_description: null,
    })

    const checkpointBtnLable = 'Add Checkpoint'
    const checkpointProcessType = 'Adding'
    const geoRequired = false
    const checkpointName = ref(null)
    const pointDescription = ref(null)
    const checkpointOrder = ref(null)
    const geoLocationLat = ref(null)
    const geoLocationLong = ref(null)
    const docsAttachmentList = ref(null)
    const nfcTag = ref(null)

    // const itemData = { checkpoints: [] }
    const itemData = ref(null)
    console.log('router.currentRoute.params: ')
    console.log(router.currentRoute.params)

    if (router.currentRoute.params.patrolname !== undefined && router.currentRoute.params.patrolname !== null
      && router.currentRoute.params.clientsite !== undefined && router.currentRoute.params.clientsite !== null
      && router.currentRoute.params.SK !== undefined && router.currentRoute.params.SK !== null) {
      console.log('Found Params in route')
      theClientSite = router.currentRoute.params.clientsite
      clientsite = router.currentRoute.params.clientsite
      thePatrolName = router.currentRoute.params.patrolname
      SK = router.currentRoute.params.SK
    }

    const CLIENT_APP_STORE_MODULE_NAME = 'app-client'
    // Register module
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME)
    })

    // Register module
    if (!store.hasModule(storeModuleName)) store.registerModule(storeModuleName, storeModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(storeModuleName)) store.unregisterModule(storeModuleName)
    })

    // Get Client Sites function - used when a client is selected
    function getPatrolTemplate(thePatrolname) {
      console.log('getPatrolTemplate')
      console.log(clientsite)
      console.log(thePatrolname)
      // toast({
      //   component: ToastificationContent,
      //   position: 'top-right',
      //   props: {
      //     title: 'Getting Patrol Template Details',
      //     icon: 'CheckIcon',
      //     variant: 'success',
      //   },
      // })
      if (ability.can('read', 'patrols')) {
        const qvars = {
          forceRefresh: false,
          q: null,
          page: 1,
          sortBy: 'patrolname',
          sortDesc: true,
          status: null,
          clientsite,
          patrolname: thePatrolname,
        }
        console.log(qvars)
        store
          .dispatch('app-patrols/fetchTemplate', qvars)
          .then(response => {
            console.log(response)
            thePatrolTemplate.value = response.data
            // Setup the itemData with the template data
            itemData.value.status = 'New'
            console.log('thePatrolTemplate')
            console.log(thePatrolTemplate)
          })
          .catch(error => {
            console.log(error)
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching Patrol Templates list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    }

    const {
      fetchListItems,
      checkpointsTableColumns,
      checkpointsperPage,
      checkpointsCurrentPage,
      totalCheckpoints,
      theList,
      theListComplete,
      dataMeta,
      checkpointsperPageOptions,
      sortBy,
      isBusy,
      isSortDirDesc,
      refListTable,
      refetchData,
    } = useList(itemData)

    return {
      userPrefs,
      userData,
      abilityRequired,
      storeModuleName,
      itemTypeName,
      allowPrinting,
      allowApproval,
      printTitle,
      filesDir,
      maxFiles,
      supportedFileTypes,
      maxImages,
      supportedImageTypes,
      theClientSite,
      clientsite,
      thePatrolName,
      SK,
      thePatrolTemplate,
      theCheckpoints,
      theCompletedCheckpoints,
      checkpointDetailsModal,
      clientsData,
      clientOptions,
      siteOptions,
      patrolnameOptions,
      sitesData,
      getPatrolTemplate,

      checkpointBtnLable,
      checkpointProcessType,
      geoRequired,
      checkpointName,
      pointDescription,
      checkpointOrder,
      geoLocationLat,
      geoLocationLong,
      docsAttachmentList,
      nfcTag,

      itemData,
      fetchListItems,
      checkpointsTableColumns,
      checkpointsperPage,
      checkpointsCurrentPage,
      totalCheckpoints,
      theList,
      theListComplete,
      dataMeta,
      checkpointsperPageOptions,
      sortBy,
      isBusy,
      isSortDirDesc,
      refListTable,
      refetchData,
    }
  },
  created() {
    // Get the item details if patrolname is passed in
    if (this.theClientSite === null) {
      this.itemData = {}
      this.itemData.patrolname = null
      this.itemData.checkpoints = []
    } else {
      this.getExistingItemData()
    }
  },
  methods: {
    checkUserPrefs() {
      if (this.userPrefs.getUserPref('patrolsClientFilter') !== null) {
        this.itemData.clientname = this.userPrefs.getUserPref('patrolsClientFilter')
        console.log('this.itemData.clientname', this.itemData.clientname)
        this.handleSelectClient(this.itemData.clientname)
      }
      if (this.userPrefs.getUserPref('patrolsSiteFilter') !== null) {
        this.itemData.sitename = this.userPrefs.getUserPref('patrolsSiteFilter')
        console.log('this.itemData.sitename', this.itemData.sitename)
        this.handleSelectSite(this.itemData.sitename)
      }
    },
    getExistingItemData() {
      console.log('getExistingItemData')
      if (this.theClientSite !== null && ability.can('read', this.abilityRequired)) {
        console.log('getting item data')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Getting ${this.itemTypeName} Data`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        const qvars = {
          abilityRequired: this.abilityRequired,
          SK: this.SK,
          clientsite: this.clientsite,
        }
        store.dispatch(`${this.storeModuleName}/fetchItem`, qvars)
          .then(response => {
            console.log(response)
            this.itemData = response.data
          })
          .catch(error => {
            console.log(error)
            let theErrorStr = 'The following error(s) where received:\n'
            if (typeof error.response.data.errors !== 'undefined') {
              error.response.data.errors.forEach(item => {
                theErrorStr += `${item.message}\n`
              })
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Error Getting ${this.itemTypeName}.`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: theErrorStr,
              },
            })
          })
      }
    },
    handleEditPatrol() {
      this.$router.push({
        name: 'apps-patrols-record',
        params: {
          patrolname: this.itemData.patrolname,
          clientsite: this.itemData.clientsite,
          clientname: this.itemData.clientname,
          sitename: this.itemData.sitename,
          SK: this.itemData.SK,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
#fileInput {
  display: none;
}
.radio-spacing .custom-control {
    margin-top: 10px; /* Adjust this value to increase or decrease spacing */
}
.sortable-chosen {
  background-color: #f8f9fa;
  cursor: grabbing;
}

.sortable-drag {
  opacity: 0.8;
}
.draggable-row {
  cursor: grab;
}
</style>
